jQuery(function ($) {

	$('.hero-list-item').on('click', function (event) {
		event.preventDefault();

		var me = $(this);

		if (me.is('.active')) {
			return;
		}

		$('.hero-list, .hero-toggle').removeClass('active');
		$('.hero-slider').slick('slickGoTo', me.data('id'));
	});

	$('.hero-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
		var id = parseInt(nextSlide, 10);

		$('.hero-list-item')
			.removeClass('active')
			.filter('[data-id="' + id + '"]').addClass('active');
	});

});